import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketball';
import SportsSoccerOutlinedIcon from '@mui/icons-material/SportsSoccer';
import SportsTennisOutlinedIcon from '@mui/icons-material/SportsTennis';
import SportsVolleyballOutlinedIcon from '@mui/icons-material/SportsVolleyball';
import {
  Gender,
  Sport,
  EventType,
  EventPrivacy,
  StageType,
} from '@prisma/client';
export const prettyGenderNames = [
  {
    type: Gender.COED,
    name: 'coed',
  },
  {
    type: Gender.MEN,
    name: 'men',
  },
  {
    type: Gender.WOMEN,
    name: 'women',
  },
];

export const prettyStageTypes = [
  {
    type: StageType.POOL_PLAY,
    name: 'pool play',
  },
  {
    type: StageType.BRACKET,
    name: 'playoffs',
  },
];

export type SportEnum = {
  type: Sport;
  name: string;
  icon: any;
};

// keep alphabetized please!
export const prettySportNames: SportEnum[] = [
  {
    type: Sport.VBALL_INDOOR,
    name: 'indoor volleyball',
    icon: SportsVolleyballOutlinedIcon,
  },
  {
    type: Sport.VBALL_SAND,
    name: 'sand volleyball',
    icon: SportsVolleyballOutlinedIcon,
  },
  {
    type: Sport.PICKLEBALL,
    name: 'pickleball',
    icon: SportsTennisOutlinedIcon,
  },
  {
    type: Sport.SOCCER,
    name: 'soccer',
    icon: SportsSoccerOutlinedIcon,
  },
  {
    type: Sport.TENNIS,
    name: 'tennis',
    icon: SportsTennisOutlinedIcon,
  },
  {
    type: Sport.TABLE_TENNIS,
    name: 'ping pong',
    icon: SportsTennisOutlinedIcon,
  },
  {
    type: Sport.SPIKEBALL,
    name: 'spikeball',
    icon: RadioButtonUncheckedIcon,
  },
  {
    type: Sport.BASKETBALL,
    name: 'basketball',
    icon: SportsBasketballOutlinedIcon,
  },
];

export const prettyEventTypeNames = [
  {
    type: EventType.PICKUP,
    name: 'pickup',
  },
  {
    type: EventType.TOURNAMENT,
    name: 'tournament',
  },
  {
    type: EventType.LEAGUE,
    name: 'league',
  },
];

export const prettyEventPrivacyNames = [
  // SHOULD ONLY BE USED BY ADMINS
  // we want to create a better ui flow for this
  {
    type: EventPrivacy.PUBLIC,
    name: 'public',
  },
  {
    type: EventPrivacy.VIEW_ONLY,
    name: 'public (view-only)',
  },
  {
    type: EventPrivacy.INVITE_ONLY_ADMIN,
    name: 'only event admins can invite',
  },
  {
    type: EventPrivacy.INVITE_ONLY_ALL,
    name: 'anyone can invite',
  },
  {
    type: EventPrivacy.INVITE_ONLY_ALL_W_LINK,
    name: 'anyone can invite (and share link)',
  },
];

export function playoffRoundNumToString(
  playoffRound: number,
  numRounds: number
) {
  const numFromEnd = numRounds - playoffRound;
  switch (numFromEnd) {
    case 2:
      return 'quarterfinals';
    case 1:
      return 'semifinals';
    case 0:
      return 'finals';
    default:
      return 'round ' + playoffRound;
  }
}
