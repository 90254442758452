import { useAuth0 } from '@auth0/auth0-react';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Logout } from '@mui/icons-material';
import { Button } from '@mui/material';

const Auth0BaseURLMobile = process.env.NEXT_PUBLIC_AUTH0_BASE_URL_MOBILE;
const Auth0BaseURLWebLogout = process.env.NEXT_PUBLIC_AUTH0_BASE_URL_WEB_LOGOUT;
const logoutUri = Capacitor.isNativePlatform()
  ? Auth0BaseURLMobile
  : Auth0BaseURLWebLogout;

const LogoutButton: React.FC = () => {
  const { logout } = useAuth0();

  const doLogout = async () => {
    await logout({
      logoutParams: {
        returnTo: logoutUri,
      },
      async openUrl(url) {
        // Redirect using Capacitor's Browser plugin
        await Browser.open({
          url,
          windowName: '_self',
        });
      },
    });
  };

  return (
    <Button size="small" color="secondary" onClick={doLogout}>
      <Logout sx={{ paddingRight: 1 }} fontSize="small" /> log out
    </Button>
  );
};

export default LogoutButton;
