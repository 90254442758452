import React, { useEffect, useState } from 'react';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';

type navOption = 'home' | 'search' | 'events' | 'teams' | 'stats';

interface NavigationOption {
  href: string;
  icon: JSX.Element;
  title: navOption;
}

const navigationOptions: NavigationOption[] = [
  { href: '/', icon: <HomeIcon />, title: 'home' },
  { href: '/search', icon: <MapIcon />, title: 'search' },
  { href: '/dashboard', icon: <CalendarTodayIcon />, title: 'events' },
  { href: '/teams', icon: <GroupIcon />, title: 'teams' },
  { href: '/stats', icon: <QueryStatsIcon />, title: 'stats' },
];

const BottomNav = () => {
  const [menuValue, setMenuValue] = useState<navOption>('home');
  const router = useRouter();

  useEffect(() => {
    // update menu value if route changes
    const currentNav = navigationOptions.find(
      item => item.href === router.pathname
    );
    if (currentNav && currentNav.title !== menuValue) {
      setMenuValue(currentNav.title);
    }
  }, [router.pathname, menuValue]);

  return (
    <BottomNavigation
      value={menuValue}
      onChange={(event, newValue) => {
        const selectedNav = navigationOptions.find(
          item => item.title === newValue
        );
        if (selectedNav && selectedNav.href !== router.pathname) {
          router.push(selectedNav.href);
        }
        setMenuValue(newValue);
      }}
      sx={{
        backgroundColor: '#424651',
      }}
    >
      {navigationOptions.map(item => (
        <BottomNavigationAction
          id={`bottom-nav-action-${item.title}`}
          key={item.title}
          label={item.title}
          value={item.title}
          icon={
            <Link href={item.href} passHref>
              {item.icon}
            </Link>
          }
          sx={{ color: 'white' }}
        />
      ))}
    </BottomNavigation>
  );
};

export default BottomNav;
