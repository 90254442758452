import { Button } from '@mui/material';
import { useRouter } from 'next/router';

import { useLogin } from '../../hooks/useLogin';

const LoginButton: React.FC = () => {
  const { route } = useRouter();
  const { login } = useLogin(route);

  return (
    <Button color="primary" variant="contained" onClick={login}>
      log in
    </Button>
  );
};

export default LoginButton;
