import { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Browser } from '@capacitor/browser';

export const useLogin = (returnTo: string) => {
  const { loginWithRedirect } = useAuth0();
  const login = useCallback(async () => {
    await loginWithRedirect({
      appState: {
        returnTo: returnTo,
      },
      async openUrl(url) {
        await Browser.open({
          url,
          windowName: '_self',
        });
      },
    });
  }, []);

  return { login };
};
