import * as React from 'react';

import styled from '@emotion/styled';
import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Sport } from '@prisma/client';
import useLocalStorageState from 'use-local-storage-state';

import { prettySportNames } from '../../../utils/enums';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
    color: #fc7c74;
  }
`;
const CustomMenu = styled(Menu)`
  .MuiPaper-root {
    background-color: #fff; /* Or any color that stands out from your page */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05); /* Optional: Adds shadow for better distinction */
  }
`;
type NavbarSportDropdownProps = {
  favoriteSports?: Sport[];
};

const NavbarSportDropdown = ({ favoriteSports }: NavbarSportDropdownProps) => {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const [selectedSport, setSelectedSport] = useLocalStorageState(
    'selectedSport',
    {
      defaultValue: prettySportNames[0].type,
    }
  );

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSelectSport = (sportType: Sport) => {
    // Update the selected sport in component state
    setSelectedSport(sportType);
    // Close the menu
    closeMenu();
  };
  const prettyFavoriteSports = prettySportNames;
  // TODO: enable this later when we do more UX testing
  // const prettyFavoriteSports = prettySportNames.filter(sport =>
  //   // if favoriteSports is empty, show all sports
  //   favoriteSports && favoriteSports.length
  //     ? favoriteSports.includes(sport.type)
  //     : true
  // );

  return (
    <React.Fragment>
      <Tooltip title="sport">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          {React.createElement(
            prettyFavoriteSports.find(sport => sport.type === selectedSport)
              ?.icon || prettyFavoriteSports[0].icon
          )}
        </IconButton>
      </Tooltip>
      <CustomMenu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {prettyFavoriteSports.map(sport => (
          <MenuItem
            dense
            key={sport.type}
            selected={sport.type === selectedSport}
            onClick={() => handleSelectSport(sport.type)}
          >
            <ListItemIcon>{React.createElement(sport.icon)}</ListItemIcon>
            <ListItemText>{sport.name}</ListItemText>
          </MenuItem>
        ))}
      </CustomMenu>
    </React.Fragment>
  );
};

export default NavbarSportDropdown;
