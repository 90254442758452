import * as React from 'react';

import styled from '@emotion/styled';
import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Button,
  Divider,
} from '@mui/material';
import Link from 'next/link';
import { User } from 'react-feather';

import { AuthUser } from '../../../hooks/useAuthUser';
import LogoutButton from '../../buttons/LogoutButton';

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;
const CustomMenu = styled(Menu)`
  .MuiPaper-root {
    background-color: #fff; /* Or any color that stands out from your page */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05); /* Optional: Adds shadow for better distinction */
  }
`;

interface NavbarUserDropdownProps {
  user: AuthUser;
}
function NavbarUserDropdown({ user }: NavbarUserDropdownProps) {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <User />
        </IconButton>
      </Tooltip>
      <CustomMenu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem dense>
          <Link href="/profile">
            <Button size="small" color="secondary">
              profile
            </Button>
          </Link>
        </MenuItem>
        {user.isSuperAdmin && (
          <>
            <MenuItem dense>
              <Link href="/orgs">
                <Button size="small" color="secondary">
                  organizations
                </Button>
              </Link>
            </MenuItem>
            <MenuItem dense>
              <Link href="/locations">
                <Button size="small" color="secondary">
                  locations
                </Button>
              </Link>
            </MenuItem>
          </>
        )}
        <Divider />
        <MenuItem dense>
          <LogoutButton />
        </MenuItem>
      </CustomMenu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
