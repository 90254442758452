import React, { useState, useEffect, useRef } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { App as CapApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import styled from '@emotion/styled';
import { CssBaseline } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { Outlet } from 'react-router-dom';

import GlobalStyle from '../components/GlobalStyle';
import AppBar from '../components/nav/AppBar';
import BottomNav from '../components/nav/BottomNav';
import useAuthUser from '../hooks/useAuthUser';
import useRegisterNotifications from '../hooks/usePushNotification';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const AppContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  max-width: 100%;
`;

interface MainContentProps {
  appBarHeight: number;
  bottomNavHeight: number;
}

const MainContent = styled.div<MainContentProps>`
  flex-grow: 1;
  padding-top: ${props => props.appBarHeight}px;
  padding-bottom: ${props => props.bottomNavHeight}px;
`;

const BottomNavWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1100;
`;

type Props = {
  children: React.ReactNode;
};

export default function MainLayout({ children }: Props) {
  const [auth0Redirect, setAuth0Redirect] = useState(false);
  const { user, isLoading: userIsLoading } = useAuthUser();
  const { handleRedirectCallback } = useAuth0();
  const shouldRegister = auth0Redirect && user != null;

  const [appBarHeight, setAppBarHeight] = useState(0);
  const [bottomNavHeight, setBottomNavHeight] = useState(0);

  const appBarRef = useRef<HTMLDivElement>(null);
  const bottomNavRef = useRef<HTMLDivElement>(null);

  useRegisterNotifications(user?.id ?? '', shouldRegister);
  const [insets, setInsets] = useState({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  });

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        setInsets(insets);
      });
    }
  }, []);

  useEffect(() => {
    // Update the height of the app bar and bottom nav
    // so that we can properly set padding on the main content
    if (appBarRef.current) {
      setAppBarHeight(appBarRef.current.offsetHeight);
    }
    if (bottomNavRef.current) {
      setBottomNavHeight(bottomNavRef.current.offsetHeight);
    }
  }, [appBarRef?.current?.offsetHeight, bottomNavRef?.current?.offsetHeight]);

  useEffect(() => {
    // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    CapApp.addListener('appUrlOpen', async ({ url }) => {
      if (
        url.includes('state') &&
        (url.includes('code') || url.includes('error'))
      ) {
        await handleRedirectCallback(url);
        if (Capacitor.getPlatform() != 'web') {
          setAuth0Redirect(true);
        }
      }
      // No-op on Android
      await Browser.close();
    });
  }, [handleRedirectCallback]);

  useEffect(() => {
    if (auth0Redirect && user != null) {
      setAuth0Redirect(false);
    }
  }, [auth0Redirect, user]);

  useEffect(() => {
    if (!userIsLoading && user) {
      try {
        const isOnboarding = isEmailIdenticialToUserName(
          user.email || '',
          user.name || ''
        );
        if (isOnboarding) {
          window.location.href = '/onboarding';
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }, [userIsLoading, user]);

  return (
    <Root
      style={{
        paddingTop: insets.top,
        paddingLeft: insets.left,
        paddingRight: insets.right,
        paddingBottom: insets.bottom,
        // Add other styles as needed
      }}
    >
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <AppBar ref={appBarRef} />
        <MainContent
          appBarHeight={appBarHeight - insets.top}
          bottomNavHeight={bottomNavHeight - insets.bottom}
        >
          {children}
          <Outlet />
        </MainContent>
        <BottomNavWrapper ref={bottomNavRef}>
          <BottomNav />
        </BottomNavWrapper>
      </AppContent>
    </Root>
  );
}

function isEmailIdenticialToUserName(email: string, userName: string) {
  // Regular expression to validate email format
  const emailFormatRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the email format is valid
  if (!emailFormatRegex.test(email)) {
    throw new Error('Invalid email format');
  }
  // Use a regular expression to match the part before the "@" symbol in the email
  const emailNameMatch = email.match(/^([^@]+)/);

  // Check if the match is found and compare it with user.name
  return emailNameMatch && emailNameMatch[1] === userName;
}
