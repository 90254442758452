import { useEffect } from 'react';

import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import useGql from './useGql';
// import { upsertOneUserNotificationPreferences } from '../api';

interface MutationParams {
  deviceId: string;
  firebaseToken: string;
  userId: string;
}

const useRegisterNotifications = (userId: string, shouldRegister: boolean) => {
  const { gqlSdk } = useGql();

  useEffect(() => {
    if (!shouldRegister) {
      return;
    }

    const register = async () => {
      // On success, we should be able to receive notifications
      await PushNotifications.addListener('registration', (token: Token) => {
        const params: MutationParams = {
          deviceId: Capacitor.getPlatform(),
          firebaseToken: token.value,
          userId: userId,
        };

        gqlSdk
          ?.upsertOneUserNotificationPreferences({
            where: {
              userId_deviceId: {
                userId: userId,
                deviceId: params.deviceId,
              },
            },
            create: {
              deviceId: params.deviceId,
              deviceName: '',
              firebaseToken: params.firebaseToken,
              pushNotify: true,
              user: {
                connect: {
                  id: userId,
                },
              },
            },
            update: {
              firebaseToken: {
                set: params.firebaseToken,
              },
              pushNotify: {
                set: true,
              },
            },
          })
          .then(() => console.error('success'))
          .catch((error: any) =>
            console.error('Push Notification Error:', error)
          );

        if (Capacitor.getPlatform() === 'android') {
          PushNotifications.createChannel({
            id: 'fcm_default_channel',
            name: 'kalicosports',
            description:
              'Show the notification if the app is open on your device',
            importance: 5,
            visibility: 1,
            lights: true,
            vibration: true,
          });
        }
      });
      // Some issue with our setup and push will not work
      await PushNotifications.addListener('registrationError', (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      });

      let permStatus = await PushNotifications.checkPermissions();
      console.log('permission' + permStatus);
      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
      }

      await PushNotifications.register();
    };
    register();

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        alert('Push action performed: ' + JSON.stringify(notification));
      }
    );

    return () => {};
  }, [userId, gqlSdk, shouldRegister]);
};

export default useRegisterNotifications;
