import React, { forwardRef } from 'react';

import styled from '@emotion/styled';
import {
  AppBar,
  Button as MuiButton,
  ButtonProps,
  Container,
  Grid,
  Toolbar,
  CircularProgress,
  AppBarProps,
} from '@mui/material';
import { spacing, SpacingProps } from '@mui/system';
import Link from 'next/link';

import NavbarSportDropdown from './navbar/NavbarSportDropdown';
import NavbarUserDropdown from './navbar/NavbarUserDropdown';
import Logo from '../../../public/logo/svg/color_logo_no_background.svg';
import useAuthUser from '../../hooks/useAuthUser';
import LoginButton from '../buttons/LoginButton';

const Button = styled(MuiButton)<
  SpacingProps & ButtonProps & { target?: string }
>(spacing);

const BrandIcon = styled(Logo)`
  margin-right: ${props => props.theme.spacing(2)};
  margin-top: -2px;
  color: ${props => props.theme.sidebar.header.brand.color};
  fill: ${props => props.theme.sidebar.header.brand.color};
  width: 100px;
  height: 100px;

  vertical-align: middle;
  display: inline;
`;

type AppBarComponentProps = Omit<
  AppBarProps,
  'position' | 'color' | 'elevation'
>;

const AppBarComponent = forwardRef<HTMLDivElement, AppBarComponentProps>(
  (props, ref) => {
    const { user, error, isLoading } = useAuthUser();
    return (
      <AppBar
        ref={ref}
        position="fixed"
        color="secondary"
        elevation={0}
        {...props}
      >
        <Toolbar>
          <Container>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'block' }}>
                <Link href="/" passHref>
                  <Button ml={2} color="inherit">
                    <BrandIcon />
                  </Button>
                </Link>
              </Grid>
              <Grid item xs />
              <Grid item>
                {isLoading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : user ? (
                  <>
                    <NavbarSportDropdown
                      favoriteSports={user.favoriteSports ?? []}
                    />
                    <NavbarUserDropdown user={user} />
                  </>
                ) : (
                  <>
                    <NavbarSportDropdown favoriteSports={[]} />
                    <LoginButton />
                  </>
                )}
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    );
  }
);

// necessary to add a displayName since using useRef on a forwardRef component
AppBarComponent.displayName = 'AppBarComponent';

export default AppBarComponent;
